function ajaxCallSync(type, url, data, successCallback, failCallback) {
    ajaxCall(false, type, url, data, successCallback, failCallback);
}

function ajaxCallAsync(type, url, data, successCallback, failCallback) {
    ajaxCall(true, type, url, data, successCallback, failCallback);
}

function ajaxCall(isSync, type, url, data, successCallback, failCallback) {
    $.ajax({
        type: type,
        url: url,
        data: data,
        dataType: 'json',
        async: isSync,
        success: successCallback,
        error: failCallback
    });
}

function ajaxCallPostSync(url, data, successCallback, failCallback) {
    ajaxCallSync("POST", url, data, successCallback, failCallback);
}

function ajaxCallPostAsync(url, data, successCallback, failCallback) {
    ajaxCallAsync("POST", url, data, successCallback, failCallback);
}

function ajaxCallGetSync(url, data, successCallback, failCallback) {
    ajaxCallSync("GET", url, data, successCallback, failCallback);
}

function ajaxCallGetAsync(url, data, successCallback, failCallback) {
    ajaxCallAsync("GET", url, data, successCallback, failCallback);
}

function activateColorFilter() {
    var categoryId = $('#selectFilterCategory').val();
    var url = 'activateColorFilter';
    var data = {"category_id":categoryId};

    if (confirm('Вы уверены что хотите активировать фильтр?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('activated');
                setTimeout(
                    function () {
                        return getPossibleFilters();
                    }, 1000);
            } else {
                alert('error activating');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error activating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function deactivateColorFilter() {
    var categoryId = $('#selectFilterCategory').val();
    var url = 'deactivateColorFilter';
    var data = {"category_id":categoryId};

    if (confirm('Вы уверены что хотите деактивировать фильтр?')) {
        var successCallback = function(result) {
            if (result.status) {
                alert('deactivated');
                setTimeout(
                    function()
                    {
                        return getPossibleFilters();
                    }, 1000);
            } else {
                alert('error deactivating');
            }
        };
        var failCallback = function(xhr, status, error) {
            alert('error deactivating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function getInfoForChoosenProduct() {
    var product = $('#productChoice').val();
    if ('' === product) {
        return;
    }
    var url = 'getProductInfo';
    var data = {"product":product};
    var successCallback = function(result) {
        $('#ChangeProductError').empty();
        if (result.status) {
            var retval = result.retval;
            var product = retval.id + ' - ' + retval.model;
            $('#ProductName').empty().val(product);
            $('#ProductDescription').empty().val(retval.description);
            $('#mainTech').empty().val(retval.mainTech);
            $('#mainTechLink').empty().val(retval.mainTechLink);
            $('#modeling3D').empty().val(retval.modeling3D);
            var technologies = retval.technologies;
            var videoLinks = retval.videoLinks;
            var stores = retval.stores;
            //technologies
            var multiChoiseArray = [];
            var option = "<option value=\"0\"></option>";
            multiChoiseArray.push(option);
            for (var row in technologies) {
                var technology = technologies[row];
                var id = technology.id;
                var name = technology.name;
                if (technology.is_selected) {
                    option = '<option selected="selected" value=' + id + '>' + name + '</option>';
                } else {
                    option = '<option value=' + id + '>' + name + '</option>';
                }
                multiChoiseArray.push(option);
            }
            //video links
            $('.form-group.admin_video_link input').val('');
            $('.form-group.admin_video_link').each(function(index) {
                if (videoLinks[index]) {
                    $(this).find("input[name=link]").val(videoLinks[index].link);
                    $(this).find("select[name=type]").val(videoLinks[index].type);
                }
            });
            //stores
            $('.form-control.shop-link').val('');
            for (var row in stores) {
                $('#' + row).val(stores[row]);
            }
            //specifications
            $('#SelectSpecifications').empty();
            var specifications = retval.specifications;
            var stringToAppend = "";
            for(var row in specifications) {
               var stringId = row.substring(0, row.indexOf(' - '));
               var stringName = row.substring(row.indexOf('-') + 2);
               stringToAppend += '<div id=' + stringId + '>' + '<strong>' + stringName + '</strong>';
               var innerArray = specifications[row];
               for (var row2 in innerArray) {
                   var innerRow = innerArray[row2];
                   var innerStringId = innerRow.substring(0, innerRow.indexOf(' - '));
                   if (0 <= innerRow.search('->')) {
                       var innerStringName = innerRow.substring(innerRow.indexOf('-') + 2, innerRow.indexOf(' ->'));
                       var stringValue = innerRow.substring(innerRow.indexOf('->') + 3);
                       stringToAppend += '<div>' + innerStringName +
                           '<input class="form-control specification-values" type="text" ' +
                           'id=' + innerStringId + ' value="' + stringValue + '">' +
                           '</div>';
                   } else {
                       var innerStringName = innerRow.substring(innerRow.indexOf('-') + 2);
                       var stringValue = '';
                       stringToAppend += '<div>' + innerStringName +
                           '<input class="form-control specification-values" type="text" ' +
                           'id=' + innerStringId + ' value="' + stringValue + '">' +
                           '</div>';
                   }
               }
               stringToAppend += '</div>';
            }
            $('#SelectSpecifications').append(stringToAppend);
            $('#SelectTechnologies').empty().append(multiChoiseArray);
            $("#ColorChoice").val(retval.colorId);
            $('#CodeAction').empty().val(retval.stockCode);
            $('#CodeEan').empty().val(retval.ean);
        }
    };
    var failCallback = function(xhr, status, error) {
        $('#ChangeProductError').empty();
        $('#ChangeProductSuccess').empty();
        $('#getProductDetailsError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}
$('#lightbox_save_message .lb-close, #lightbox_save_message').click(function() {
    $('#lightbox_save_message').fadeOut();
    $('#lightbox_save_message').removeClass('show_message');
    return false;
});
function showSaveMessage(type, text) {    
    if (type == "error") {        
        $('#lightbox_save_message').addClass('show_message');
        $('#lightbox_save_message').fadeIn(function () {
            $('#ChangeProductSuccess').empty().fadeOut();
            $('#ChangeProductError').empty().append(text);
            $('#ChangeProductError').fadeIn();
           setTimeout( function() {
                $('#lightbox_save_message').fadeOut();
                $('#lightbox_save_message').removeClass('show_message');
                $('#ChangeProductError').fadeOut();                
            }, 5000);
        });
        
    }
    else if(type == "success"){        
        $('#lightbox_save_message').addClass('show_message');
        $('#lightbox_save_message').fadeIn(function () {
            $('#ChangeProductError').empty().fadeOut();
            $('#ChangeProductSuccess').empty().append(text);
            $('#ChangeProductSuccess').fadeIn();
            setTimeout( function() {
                $('#lightbox_save_message').fadeOut();
                $('#lightbox_save_message').removeClass('show_message');
                $('#ChangeProductSuccess').fadeOut();
            }, 5000);
        });
    }
}

function showSaveMessageLite() {
    $('#lightbox_save_message').addClass('show_message_lite');
    $('#lightbox_save_message').fadeIn(function () {
       setTimeout( function() {
            $('#lightbox_save_message').fadeOut();
            $('#lightbox_save_message').removeClass('show_message_lite');              
        }, 5000);
    });
}

function changeProduct() {
    var name = $('#ProductName').val();
    var colorId = $("#ColorChoice").val();
    var codeAction = $('#CodeAction').val();
    var codeEan = $('#CodeEan').val();
    if (('' === name) || ('' === colorId) || ('' === codeAction) || ('' === codeEan)) {        
        showSaveMessage('error', 'Не заполены обязательные поля');    
        return;
    }
    var mainTech = $('#mainTech').val();
    var mainTechLink = $('#mainTechLink').val();
    var description = $('#ProductDescription').val();
    var technologies = $("#SelectTechnologies").val();
    var modeling3D = $('#modeling3D').val();
    var videoLinks = [];
    $('.form-group.admin_video_link').each(function() {
        videoLinks.push({
            link: $(this).find("input[name=link]").val(),
            type: $(this).find("select[name=type]").val(),
        });
    });
    var stores = {};
    $('.form-control.shop-link').each(function(){
        stores[$(this).attr('id')] = $(this).val();
    });
    var specifications = {};
    $('#SelectSpecifications input').each(function(){
        if ('' !== this.value) {
            var parentId = $(this).parent().parent().prop('id');
            if (false == specifications.hasOwnProperty(parentId)) {
                specifications[parentId] = {};
                specifications[parentId][this.id] = this.value;
            } else {
                specifications[parentId][this.id] = this.value;
            }
        }
    });
    var url = 'change';
    var data = {"name": name,"description": description,"colorId": colorId,
        "codeAction":codeAction, "codeEan":codeEan, "videoLinks":videoLinks,
        "technologies":technologies, "specifications":specifications, "stores":stores,
        "modeling3D": modeling3D, "mainTech": mainTech, "mainTechLink": mainTechLink};
    var successCallback = function(result) {
        $('#ChangeProductError').empty();
        if (result.status) {
            showSaveMessage('success', 'Сохранено успешно!'); 
            setTimeout(
                function()
                {
                    cleanForm();
                }, 3000);
        }
    };
    var failCallback = function(xhr, status, error) {
        showSaveMessage('error', 'problem changing product details'); 
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function updateMultipleProductsGuaranteeText() {
    var guaranteeProducts = $('#guaranty_prosmart').val();
    var guaranteeText = $('#add_guarantee_text').val();
    var data = {'products': guaranteeProducts, 'text': guaranteeText};
    var url = 'updateMultipleProductsGuarantee';
    updateMultipleRows(url, data);
}

function updateMultipleProductsRating() {
    var ids = $('.rating-option').map(function() {
        return $(this).attr('id');
    });
    updateMultipleProducts(ids, 'updateMultipleProductsRating');
}

function updateMultipleProductsLabels() {
    var ids = $('.label-option').map(function() {
        return $(this).attr('id');
    });
    updateMultipleProducts(ids, 'updateMultipleProductsLabels');
}

function updateMultipleProducts(ids, action) {
    var finalArray = [];
    var idsCount = ids.length;
    for (var i = 0; i < idsCount; ++i) {
        var attribute = ids[i];
        var products = $('#' + attribute).val();
        var obj = {"attribute":attribute, "products":products};
        finalArray.push(obj);
    }
    var url = action;
    var data = {"data":finalArray};
    updateMultipleRows(url, data);
}

function updateMultipleRows(url, data) {
    var successCallback = function(result) {
        $('#ChangeProductError').empty();
        if (result.status) {
            showSaveMessage('success', 'Сохранено успешно!');
            setTimeout(
                function()
                {
                    cleanForm();
                }, 3000);
        }
    };
    var failCallback = function(xhr, status, error) {
        showSaveMessage('error', 'problem changing multiple products');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function getInfoForChoosenCategory() {
    var category = $("#selectProductCategory option:selected" ).text();
    if ('' === category) {
        return;
    }
    var url = 'getTechnologies';
    var data = {"category":category};
    var successCallback = function(result) {
        $('#SaveNewProductError').empty();
        if (result.status) {
            var retval = result.retval;
            retval = JSON.parse(retval);
            var multiChoiseArray = [];
            var option = "<option value=\"0\"></option>";
            multiChoiseArray.push(option);
            for(var row in retval) {
                option = '<option value=' + row + '>' + retval[row] + '</option>';
                multiChoiseArray.push(option);
            }
            $('#SelectTechnologies').empty().append(multiChoiseArray);
            getSpecifications(category);
        }
    };
    var failCallback = function() {
        $('#SaveNewProductSuccess').empty();
        $('#SaveNewProductError').empty();
        $('#getInfoForChoosenCategoryError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

//attributes
function getExistingAttributesForChange() {
    var category = $("#selectAttributesCategory option:selected" ).text();
    if ('' === category) {
        return;
    }
    var url = 'getCategoryAttributes';
    var data = {"category":category};
    var successCallback = function(result) {
        $('#AddAttributeError').empty();
        $('#attributesTableBody').empty()
        if (result.status) {
            var retval = result.retval;
            for(var i in retval) {
                var string = '';
                var id = retval[i].id;
                var name = retval[i].name;
                var isActive = retval[i].is_active;
                if (isActive) {
                    string += '<tr id="' + id + '">';
                } else {
                    string += '<tr class="dis_product" id="' + id + '">';
                }
                string += '<td>' + name + '</td>';
                var buttons = drawAttributeActivationButtons(id, name, isActive);
                string += '<td class="btns_wrapp" id="btns-wrapper' + id + '">' + buttons;
                $('#attributesTableBody').append(string);
            }
        }
    };
    var failCallback = function() {
        $('#AddAttributeSuccess').empty();
        $('#AddAttributeError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function drawAttributeActivationButtons(id, name, isActive) {
    var buttons = '';
    name = '\'' + name + '\'';
    if (isActive) {
        buttons += '<button type="button" class="btn btn-success" disabled title="Активировать"><span class="glyphicon glyphicon-ok"></span></button>';
        buttons += '<button type="button" class="btn btn-danger" onclick="deactivateAttribute(' + id + ',' + name + ')" title="Удалить (деактивировать)"><span class="glyphicon glyphicon-remove"></span></button>';
    } else {
        buttons += '<button type="button" class="btn btn-success" onclick="activateAttribute(' + id + ',' + name + ')" title="Активировать"><span class="glyphicon glyphicon-ok"></span></button>';
        buttons += '<button type="button" class="btn btn-danger" disabled title="Удалить (деактивировать)"><span class="glyphicon glyphicon-remove"></span></button>';
    }
    return buttons;
}

function activateAttribute(id, name) {
    var url = 'activateAttribute';
    var data = {"id":id};

    if (confirm('Вы уверены что хотите активировать атрибут?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('activated');
                var buttons = drawAttributeActivationButtons(id, name, true);
                $('#' + id).removeClass('dis_product');
                $('#btns-wrapper' + id).empty().append(buttons);
            } else {
                alert('error activating');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error activating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function deactivateAttribute(id, name) {
    var url = 'deactivateAttribute';
    var data = {"id":id};

    if (confirm('Вы уверены что хотите деактивировать атрибут?')) {
        var successCallback = function(result) {
            if (result.status) {
                alert('deactivated');
                var buttons = drawAttributeActivationButtons(id, name, false);
                $('#' + id).addClass('dis_product');
                $('#btns-wrapper' + id).empty().append(buttons);
            } else {
                alert('error deactivating');
            }
        };
        var failCallback = function(xhr, status, error) {
            alert('error deactivating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function getExistingAttributes() {
    var category = $("#selectAttributesCategory option:selected" ).text();
    if ('' === category) {
        return;
    }
    var url = 'getCategoryAttributes';
    var data = {"category":category};
    var successCallback = function(result) {
        $('#AddAttributeError').empty();
        if (result.status) {
            var tableBody = [];
            var retval = result.retval;
            for(var i in retval) {
                var string = '';
                string += '<tr>';
                string += '<td>' + retval[i].name + '</td>';
                string += '<td>' + retval[i].is_main + '</td>';
                string += '<td>' + retval[i].sort_order + '</td>';
                string += '</tr>';
                tableBody.push(string);
            }
            $('#attributesTableBody').empty().append(tableBody);
        }
    };
    var failCallback = function() {
        $('#AddAttributeSuccess').empty();
        $('#AddAttributeError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function createNewAttribute() {
    var category = $("#selectAttributesCategory option:selected" ).text();
    if ('' === category) {
        return;
    }
    var nameEng = $('#name_eng').val();
    var nameRus = $('#name_rus').val();
    var isMain = $('#is_main').val();
    var sortOrder = $('#sort_order').val();
    var isNumeric = $('#is_numeric').val();
    var units = $('#units').val();
    //should not be empty
    if (('' === nameEng) || ('' === nameRus) || ('' === isMain) || ('' === sortOrder)
        || ('' === isNumeric)) {
        return;
    }
    var url = 'createNewAttribute';
    var data = {"category":category, "nameEng":nameEng, "nameRus":nameRus, "isMain":isMain,
                "sortOrder":sortOrder, "isNumeric":isNumeric, "units":units};
    var successCallback = function(result) {
        $('#AddAttributeError').empty();
        if (result.status) {
            $('#AddAttributeSuccess').empty().append('successfully saved');
            setTimeout(
                function()
                {
                    cleanForm();
                }, 3000);
        }
    };
    var failCallback = function(xhr, status, error) {
        $('#AddAttributeSuccess').empty();
        $('#AddAttributeError').empty().append('problem saving');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function getExistingAttributesForCategory() {
    var category = $("#changeAttribute option:selected" ).text();
    if ('' === category) {
        return;
    }
    var url = 'getCategoryAttributes';
    var data = {"category":category};
    var successCallback = function(result) {
        $('#AddAttributeError').empty();
        $('#existingAttributes').empty();
        if (result.status) {
            var retval = result.retval;
            var existingAttributes = [];
            var emptyAttribute = '<option value="0">Не выбрано</option>';
            existingAttributes.push(emptyAttribute);
            for(var i in retval) {
                var attribute = '';
                var id = retval[i].id;
                var name = retval[i].name;
                attribute = '<option id=' + id + '>' + name + '</option>';
                existingAttributes.push(attribute);
            }
            $('#existingAttributes').empty().append(existingAttributes);
        }
    };
    var failCallback = function() {
        $('#existingAttributes').empty();
        $('#AddAttributeSuccess').empty();
        $('#AddAttributeError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function getAttributeForChange() {
    var attributeId = $('#existingAttributes').children(":selected").attr("id");
    if ('' === attributeId) {
        return;
    }
    var url = 'getAttributeInfoForChange';
    var data = {"id":attributeId};
    var successCallback = function(result) {
        $('#AddAttributeError').empty();
        $('.change_attribute_input').val('');
        if (result.status) {
            var retval = result.retval;
            for(var i in retval) {
                $('#name_eng_change').val(retval[i].name_eng);
                $('#name_rus_change').val(retval[i].name_rus);
                $('#is_main_change').val(retval[i].is_main);
                $('#is_active_change').val(retval[i].is_active);
                $('#sort_order_change').val(retval[i].sort_order);
                $('#is_numeric_change').val(retval[i].is_numeric);
                $('#units_change').val(retval[i].units);
            }
        }
    };
    var failCallback = function() {
        $('#AddAttributeSuccess').val('');
        $('#AddAttributeError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function changeExistingAttributeData() {
    var id = $('#existingAttributes').children(":selected").attr("id");
    if ('' === id) {
        return;
    }
    var nameEng = $('#name_eng_change').val();
    var nameRus = $('#name_rus_change').val();
    var isMain = $('#is_main_change').val();
    var isActive = $('#is_active_change').val();
    var sortOrder = $('#sort_order_change').val();
    var isNumeric = $('#is_numeric_change').val();
    var units = $('#units_change').val();

    //should not be empty
    if (('' === nameEng) || ('' === nameRus) || ('' === isMain) || ('' === isActive)
        || ('' === sortOrder) || ('' === isNumeric)) {
        return;
    }
    var url = 'changeExistingAttribute';
    var data = {"id":id, "nameEng":nameEng, "nameRus":nameRus, "isMain":isMain, "isActive":isActive,
        "sortOrder":sortOrder, "isNumeric":isNumeric, "units":units};
    var successCallback = function(result) {
        $('#AddAttributeError').empty();
        if (result.status) {
            $('#AddAttributeSuccess').empty().append('successfully changed');
            setTimeout(
                function()
                {
                    $('#existingAttributes').val("0");
                    cleanForm();
                }, 3000);
        }
    };
    var failCallback = function(xhr, status, error) {
        $('#AddAttributeSuccess').empty();
        $('#AddAttributeError').empty().append('problem changing');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function getSpecifications(category) {
    var url = 'getSpecifications';
    var data = {"category":category};
    var successCallback = function(result) {
        $('#SaveNewProductError').empty();
        $('#SelectSpecifications').empty();
        if (result.status) {
           var retval = result.retval;
           retval = JSON.parse(retval);
           var stringToAppend = "";
           for(var row in retval) {
               var stringId = row.substring(0, row.indexOf(' -> '));
               var stringName = row.substring(row.indexOf('->') + 3);
               stringToAppend += '<div id=' + stringId + '>' + '<strong>' + stringName + '</strong>';
               var innerArray = retval[row];
               for (var row2 in innerArray) {
                   var innerRow = innerArray[row2];
                   var innerStringId = innerRow.substring(0, innerRow.indexOf(' -> '));
                   var innerStringName = innerRow.substring(innerRow.indexOf('->') + 3);
                   stringToAppend += '<div>' + innerStringName +
                       '<input class="form-control specification-values" type="text" id=' + innerStringId + '>' + '</div>';
               }
               stringToAppend += '</div>';
           }
           $('#SelectSpecifications').append(stringToAppend);
        }
    };
    var failCallback = function() {
        $('#SaveNewProductSuccess').empty();
        $('#SaveNewProductError').empty();
        $('#SelectSpecificationsError').empty().append('problem getting info');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function showProductPhotos() {
    var product = $('#selectProduct').val();
    if ('' === product) {
        return;
    }
    var url = 'getProductPhotosAndInstructions';
    var data = {"product_id":product};
    var successCallback = function(result) {
        $('.image-placeholder').empty();
        if (result.status) {
            var instructionId = 0;
            var photoId = 0;
            var retval = result.retval;
            for (var id in retval) {
                var row = retval[id];
                if (undefined == row.photo_name) {
                    instructionId++;
                    var pdfName = row.pdf;
                    var instrNumberId = 'instr' + instructionId;
                    var fileName = 'Instruction ' + instructionId;
                    var html = '<a href=' + encodeURI(pdfName) + ' target="_blank">' + fileName + '</a>';
                    $('#'+instrNumberId).append(html);
                } else {
                    photoId++;
                    var photoName = row.photo_name;
                    var imageNumberId = 'image' + photoId;
                    var html = '<a href=' + photoName + ' data-lightbox="lightbox" class="add_img_prew"><img src=' + photoName + '/></a>';
                    $('#'+imageNumberId).append(html);
                }
            }
        }
    };
    var failCallback = function(xhr, status, error) {
        $('.image-placeholder').empty();
        $('#ChangeProductImagesSuccess').empty();
        $('#ChangeProductImagesError').empty().append('problem downloading product images');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function saveNewProduct() {
    var name = $('#ProductName').val();
    var category = $("#selectProductCategory option:selected" ).text();
    var codeAction = $('#CodeAction').val();
    var codeEan = $('#CodeEan').val();
    if (('' === name) || ('' === category) || ('' === codeAction) || ('' === codeEan)) {
        $('#SaveNewProductError').empty().append('missing mandatory fields');
        return;
    }
    var description = $('#ProductDescription').val();
    var technologies = JSON.stringify($('#SelectTechnologies').val());
    var color = $('#ColorChoice option:selected').text();
    var mainTech = $('#mainTech').val();
    var mainTechLink = $('#mainTechLink').val();
    var modeling3D = $('#modeling3D').val();

    var specifications = {};
    $('#SelectSpecifications input').each(function(){
        if ('' !== this.value) {
            var parentId = $(this).parent().parent().prop('id');
            if (false == specifications.hasOwnProperty(parentId)) {
                specifications[parentId] = {};
                specifications[parentId][this.id] = this.value;
            } else {
                specifications[parentId][this.id] = this.value;
            }
        }
    });
    var url = 'create';
    var data = {"name": name,"category": category,"description": description,
                "technologies":technologies, "color": color, "codeAction": codeAction,
                "codeEan": codeEan, "specifications": specifications, "modeling3D": modeling3D,
                "mainTech": mainTech, "mainTechLink": mainTechLink};
    var successCallback = function(result) {
        $('#SaveNewProductError').empty();
        if (result.status) {
            $('#SaveNewProductSuccess').empty().append('successfully saved');
            setTimeout(
                function()
                {
                    cleanForm();
                }, 3000);
        }
    };
    var failCallback = function(xhr, status, error) {
        $('#SaveNewProductSuccess').empty();
        $('#SaveNewProductError').empty().append('problem creating new product');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function removeProduct(productId) {
    var url = 'deactivate';
    var data = {"product_id":productId};

    if (confirm('Вы уверены что хотите деактивировать товар?')) {
        var successCallback = function(result) {
            if (result.status) {
                alert('deactivated');
                setTimeout(
                    function()
                    {
                        location.reload();
                    }, 1000);
            } else {
                alert('error deactivating');
            }
        };
        var failCallback = function(xhr, status, error) {
            alert('error deactivating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function createdSetProduct(productId) {
    var url = 'createdSet';
    var data = {"product_id":productId};

    if (confirm('Вы уверены что хотите переместить товар в созданные?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('set to created');
                setTimeout(
                    function () {
                        location.reload();
                    }, 1000);
            } else {
                alert('error setting to created');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error setting to created');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function oldSetProduct(productId) {
    var url = 'oldSet';
    var data = {"product_id":productId};

    if (confirm('Вы уверены что хотите установить товар как устаревший?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('set to old');
                setTimeout(
                    function () {
                        location.reload();
                    }, 1000);
            } else {
                alert('error setting to old');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error setting to old');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function activateProduct(productId) {
    var url = 'activate';
    var data = {"product_id":productId};

    if (confirm('Вы уверены что хотите активировать товар?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('activated');
                setTimeout(
                    function () {
                        location.reload();
                    }, 1000);
            } else {
                alert('error activating');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error activating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function deactivateInstruction(instructionId) {
    var url = 'deactivateInstruction';
    var data = {"instruction_id":instructionId};

    if (confirm('Вы уверены что хотите деактивировать инструкцию?')) {
        var successCallback = function(result) {
            if (result.status) {
                alert('deactivated');
                setTimeout(
                    function()
                    {
                        location.reload();
                    }, 1000);
            } else {
                alert('error deactivating');
            }
        };
        var failCallback = function(xhr, status, error) {
            alert('error deactivating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function trashSetProduct(productId) {
    var url = 'trashSet';
    var data = {"product_id":productId};

    if (confirm('Вы уверены что хотите положить товар в мусор?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('set to trash');
                setTimeout(
                    function () {
                        location.reload();
                    }, 1000);
            } else {
                alert('error setting to trash');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error setting to trash');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function activateInstruction(instructionId) {
    var url = 'activateInstruction';
    var data = {"instruction_id":instructionId};

    if (confirm('Вы уверены что хотите активировать инструкцию?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('activated');
                setTimeout(
                    function () {
                        location.reload();
                    }, 1000);
            } else {
                alert('error activating');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error activating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function deactivatePhoto(photoId) {
    var url = 'deactivatePhoto';
    var data = {"photo_id":photoId};

    if (confirm('Вы уверены что хотите деактивировать фото?')) {
        var successCallback = function(result) {
            if (result.status) {
                alert('deactivated');
                setTimeout(
                    function()
                    {
                        location.reload();
                    }, 1000);
            } else {
                alert('error deactivating');
            }
        };
        var failCallback = function(xhr, status, error) {
            alert('error deactivating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

function activatePhoto(photoId) {
    var url = 'activatePhoto';
    var data = {"photo_id":photoId};

    if (confirm('Вы уверены что хотите активировать фото?')) {
        var successCallback = function (result) {
            if (result.status) {
                alert('activated');
                setTimeout(
                    function () {
                        location.reload();
                    }, 1000);
            } else {
                alert('error activating');
            }
        };
        var failCallback = function (xhr, status, error) {
            alert('error activating');
        };
        ajaxCallPostSync(url, data, successCallback, failCallback);
    }
}

// setup schemes
function getPairedProducts() {
    var schemeId = $('#unPair option:selected').val();
    var data = {"scheme_id": schemeId};
    $('#productsToUnpair').val('');
    var url = 'getProductsByScheme';
    var successCallback = function(result) {
        if (result.status) {
           $('#productsToUnPair').val(result.products);
        }
    };
    var failCallback = function(xhr, status, error) {
        console.log(error);
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function pairSchemeAndProducts() {
    var products = $('#productsToPair').val();
    var schemeId = $('#pair option:selected').val();
    var url = 'pair';
    var data = {"scheme_id": schemeId, "products": products};
    updateMultipleRows(url, data);
}

function unPairSchemeAndProducts() {
    var products = $('#productsToUnPair').val();
    var schemeId = $('#unPair option:selected').val();
    var url = 'unPair';
    var data = {"scheme_id": schemeId, "products": products};
    updateMultipleRows(url, data);
}

//login
function adminLogin() {
    var email = $('#Email1').val();
    var password = $('#Password1').val();
    var url = 'login';
    var data = {"email":email,"password":password};
    var successCallback = function (result) {
        $('#ErrorMsg').empty();
        if (result.status) {
            var redirectPage = 'admin-products-list';
            $(location).attr('href', redirectPage);
        }
        else {
            $('#ErrorMsg').append('wrong login or password');
        }
    };
    var failCallback = function() {
        $('#ErrorMsg').empty().append('wrong login or password');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function adminChangePassword() {
    var email = $('#Email').val();
    var pass = $('#Pass').val();
    var newPass = $('#NewPass').val();
    var verifyNewPass = $('#VerifyNewPass').val();
    var url = 'changePassword';
    var data = {
        "email": email,
        "pass": pass,
        "newPass": newPass,
        "verifyNewPass": verifyNewPass
    };
    var successCallback = function (result) {
        $('#ErrorMsg').empty();
        if (result.status) {
            var redirectPage = 'admin-enter';
            $(location).attr('href', redirectPage);
        }
        else {
            $('#ErrorMsg').append('wrong data provided');
        }
    };
    var failCallback = function() {
        $('#ErrorMsg').empty().append('wrong data provided');
    };
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function adminLogout() {
    var url = 'logout';
    var data = {};
    var successCallback = function (result) {
        var redirectPage = 'admin-enter';
        $(location).attr('href', redirectPage);
    };
    var failCallback = function() {};
    ajaxCallPostSync(url, data, successCallback, failCallback);
}

function cleanForm() {
    $(':input','#admin_product_form')
        .not(':button, :submit, :reset, :hidden')
        .val('')
        .prop('checked', false)
        .prop('selected', false)
    ;
    $('#pair').val('');
    $('#unPair').val('');
    $('textarea').val('');
    $('.change_attribute_input').val("");
    $('#SelectTechnologies').empty();
    $('#SelectSpecifications').empty();
    $('#attributesTableBody').empty();
    $('#selectProductCategory').val('');
    $('#relatedCategories').empty();
    $('#activeQuestions').empty();
    $('#testQuestionOrder').val('');
    $('#testQuestionText').val('');
}

//running scripts
function runFlixScript() {
    return runScript('creationXML', 'flix');
}

function runWtbScript() {
    return runScript('createWtbXml', 'wtbXML');
}

function runMonitoringScript() {
    return runScript('', 'monitoring');
}

function runScript(scriptRoute, scriptName) {
    var url = scriptRoute;
    var data = {};
    var responseMsgDiv = scriptName + "ScriptResponse";
    var successCallback = function (result) {
        if (result.status) {
            $('#' + responseMsgDiv).html("<span class=\"echo_success\">Всё получилось!</span>");
        } else {
            $('#' + responseMsgDiv).html("<span class=\"echo_error\">Что-то пошло не так(</span>");
        }
    };
    var failCallback = function(xhr, status, error) {
        $('#' + responseMsgDiv).html("<span class=\"echo_error\">Что-то пошло не так(</span>");
    };
    ajaxCallGetSync(url, data, successCallback, failCallback);
}
