$(document).ready(function () {
    $('.rutube_thumb').each(function () {
        $.ajax({
            type: 'GET',
            url: 'https://rutube.ru/api/video/' + $(this).data('ytb'),
            success: function (response) {
                console.log(response);
                $(this).href(response.data.thumbnail_url);
                $(this).find('img').src(response.data.thumbnail_url);
            }
        });
    });
})

